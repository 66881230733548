import { createSlice } from "@reduxjs/toolkit";
import { getAllOnGoingCarTransactions } from "../thunks/carTransactionsThunks";

const carTransactionsSlice = createSlice({
  name: "carTransactions",
  initialState: {
    transactionsData: [],
    selectedTransaction: null,
    status: "idle",
    error: null,
  },
  reducers: {
    clearCarTransactions(state) {
      state.transactionsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOnGoingCarTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllOnGoingCarTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transactionsData = action.payload;
      })
      .addCase(getAllOnGoingCarTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearCarTransactions } = carTransactionsSlice.actions;
export default carTransactionsSlice.reducer;
