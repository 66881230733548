import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import vehicleReducer from "./slices/vehicleSlice";
import travelerReducer from "./slices/travelerSlice";
import borderAlertsReducer from "./slices/borderAlertsSlice";
import transactionsReducer from "./slices/transactionsSlice";
import carTransactionsReducer from "./slices/carTransactionsSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    vehicles: vehicleReducer,
    travelers: travelerReducer,
    borderAlerts: borderAlertsReducer,
    transactions: transactionsReducer,
    carTransactions: carTransactionsReducer,
  },
});

export default store;
