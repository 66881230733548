import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Chip,
  Divider,
} from "@mui/material";

const PassengerDisplay = ({ passengers, handleShowInfo }) => {
  return (
    <List sx={{ width: "100%" }}>
      {passengers.length > 0 ? (
        passengers.map((passenger, index) => (
          <React.Fragment key={passenger.transactionUuid}>
            {index > 0 && <Divider />}
            <ListItem
              sx={{
                py: 1.5,
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Chip
                  label={index + 1}
                  size="small"
                  color="primary"
                  sx={{ mr: 2, minWidth: 32 }}
                />
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      {passenger.firstname} {passenger.lastname}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      Status: {passenger.transactionStatus}
                    </Typography>
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleShowInfo(passenger.transactionUuid)}
                >
                  View Info
                </Button>
              </Box>
            </ListItem>
          </React.Fragment>
        ))
      ) : (
        <ListItem>
          <ListItemText
            primary={
              <Typography align="center" color="text.secondary">
                No passengers registered
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

export default PassengerDisplay;
