import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Paper, Grid2 } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllOnGoingTransactions,
  addNewBorderAlert,
  fetchAccessToken,
} from "../../../redux";
import { getColumns } from "./components/columnsConfig";
import { setupAlertWebSocket } from "../../../utils/websocket";
import TextConstants from "../../../utils/constants/TextConstants";
import { useIsMobileImage } from "../../../hooks/useMediaQueries";
import BorderAlerts from "../../../components/alerts/BorderAlerts";

const TransactionsDashboard = () => {
  const dispatch = useDispatch();

  const transactionsData = useSelector(
    (state) => state.transactions.transactionsData
  );

  const isMobile = useIsMobileImage();

  const borderAlertsState = useSelector((state) => state.borderAlerts.alerts);
  const [borderAlerts, setBorderAlerts] = useState(borderAlertsState);

  useEffect(() => {
    setBorderAlerts(borderAlertsState);
  }, [borderAlertsState]);

  // Check local storage for user and password when the app loads
  // useEffect(() => {
  //   const storedUsername =
  //     localStorage.getItem("username") || sessionStorage.getItem("username");
  //   const storedPassword =
  //     localStorage.getItem("password") || sessionStorage.getItem("password");

  //   if (storedUsername && storedPassword) {
  //     dispatch(
  //       fetchAccessToken({ username: storedUsername, password: storedPassword })
  //     )
  //       .then((result) => {
  //         if (result.payload) {
  //           const token = result.payload;
  //           dispatch(getAllOnGoingTransactions(token));
  //         } else {
  //           console.error("Failed to fetch access token or user info");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching access token or user info", error);
  //       });
  //   }
  //   // Set up WebSocket for alerts
  //   const cleanupAlertWebSocket = setupAlertWebSocket((newAlertData) => {
  //     dispatch(addNewBorderAlert(newAlertData));
  //   });
  //   // Cleanup function for both WebSockets
  //   return () => {
  //     cleanupAlertWebSocket();
  //   };
  // }, [dispatch]);

  useEffect(() => {
    const storedUsername =
      localStorage.getItem("username") || sessionStorage.getItem("username");
    const storedPassword =
      localStorage.getItem("password") || sessionStorage.getItem("password");

    let intervalId;

    if (storedUsername && storedPassword) {
      const fetchData = () => {
        dispatch(
          fetchAccessToken({
            username: storedUsername,
            password: storedPassword,
          })
        )
          .then((result) => {
            if (result.payload) {
              const token = result.payload;
              dispatch(getAllOnGoingTransactions(token));
            } else {
              console.error("Failed to fetch access token or user info");
            }
          })
          .catch((error) => {
            console.error("Error fetching access token or user info", error);
          });
      };

      // Call fetchData immediately
      fetchData();

      // Set up polling every 2 seconds
      intervalId = setInterval(() => {
        fetchData();
      }, 2000);
    }

    // Set up WebSocket for alerts
    const cleanupAlertWebSocket = setupAlertWebSocket((newAlertData) => {
      dispatch(addNewBorderAlert(newAlertData));
    });

    // Cleanup function for both WebSocket and interval
    return () => {
      cleanupAlertWebSocket();
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [dispatch]);

  // Info display handler
  const handleShowInfo = (transaction) => {
    const url = `${TextConstants.TRANSACTIONS_DECISION_ROUTE}${transaction.transactionUuid}`;
    // window.open(url, "_blank"); // Opens the URL in a new tab
    window.location.href = url; // Opens the URL in a same tab
  };

  const columns = getColumns(handleShowInfo);

  return (
    <Box sx={{ width: "100%" }}>
      {borderAlerts.length >= 1 && <BorderAlerts alerts={borderAlerts} />}

      {/* Real-time Transactions Table */}
      {/* <Grid2 container spacing={3}> */}
      <Grid2 xs={12} sm={50} md={isMobile ? 6 : 3}>
        <Paper elevation={3} sx={{ p: 3, mb: 3, mt: 3, width: "100%" }}>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "medium",
                fontFamily: "BlinkMacSystemFont",
                color: "primary.main",
              }}
            >
              {TextConstants.REAL_TIME_TAVELERS_HEADER}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              {TextConstants.REAL_TIME_TRAVELERS_SUBTITLE}
            </Typography>
          </Box>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={transactionsData}
              columns={columns}
              disableRowSelectionOnClick
              disableVirtualization
            />
          </Box>
        </Paper>
      </Grid2>
    </Box>
  );
};

export default TransactionsDashboard;
