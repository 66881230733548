import React from "react";
// Material UI imports needed:
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";

const calculateStats = (data) => {
  const totalTransactions = data.length;
  const avgConfidence = (
    (data.reduce(
      (sum, item) => sum + parseFloat(item.licensePlateConfidence),
      0
    ) /
      totalTransactions) *
    100
  ).toFixed(1);

  const statusCounts = data.reduce((acc, item) => {
    acc[item.transactionStatus] = (acc[item.transactionStatus] || 0) + 1;
    return acc;
  }, {});

  // Find duplicates
  const plateCounts = {};
  const plateConfidences = {};
  data.forEach((item) => {
    plateCounts[item.licensePlate] = (plateCounts[item.licensePlate] || 0) + 1;
    if (!plateConfidences[item.licensePlate]) {
      plateConfidences[item.licensePlate] = [];
    }
    plateConfidences[item.licensePlate].push(
      parseFloat(item.licensePlateConfidence)
    );
  });

  const duplicates = Object.entries(plateCounts)
    .filter(([_, count]) => count > 1)
    .map(([plate, count]) => ({
      plate,
      count,
      avgConfidence: (
        (plateConfidences[plate].reduce((a, b) => a + b, 0) / count) *
        100
      ).toFixed(1),
    }));

  return {
    totalTransactions,
    avgConfidence,
    statusCounts,
    duplicates,
  };
};

const VehicleStatistics = () => {
  const data = useSelector((state) => state.carTransactions.transactionsData);
  const stats = calculateStats(data);

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Vehicle License Plate Analysis
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* Total Transactions Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                Total Transactions
              </Typography>
              <Typography variant="h3" color="primary">
                {stats.totalTransactions}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Average Confidence Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                Average Confidence Score
              </Typography>
              <Typography variant="h3" color="primary">
                {stats.avgConfidence}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={parseFloat(stats.avgConfidence)}
                sx={{ mt: 2 }}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Status Distribution Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                Transaction Status
              </Typography>
              <Box sx={{ mt: 2 }}>
                {Object.entries(stats.statusCounts).map(([status, count]) => (
                  <Box key={status} sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="body2">{status}</Typography>
                      <Typography variant="body2">{count}</Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={(count / stats.totalTransactions) * 100}
                    />
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Duplicates Table */}
      <Card>
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            Duplicate License Plates
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>License Plate</TableCell>
                  <TableCell>Occurrences</TableCell>
                  <TableCell>Avg. Confidence</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stats.duplicates.map((item) => (
                  <TableRow key={item.plate}>
                    <TableCell>{item.plate}</TableCell>
                    <TableCell>{item.count}</TableCell>
                    <TableCell>{item.avgConfidence}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VehicleStatistics;
