import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getSpecificTransaction,
  setTransactionDecision,
} from "../../../../services/transactions";
import { useDispatch } from "react-redux";
import { fetchAccessToken } from "../../../../redux";
import TableSkeleton from "../../../../components/skeletons/TableSkeleton";
import { isMobileApp } from "../../../../utils/helpers";
import { useNavigate } from "react-router-dom";

const useTransactionUpdate = (token) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateTransaction = useCallback(
    async (transactionUuid, decision, actor = "user") => {
      if (!token) {
        setError("Token is missing. Please log in again.");
        return null;
      }

      setIsLoading(true);
      setError(null);
      setSuccess(false);

      try {
        const data = await setTransactionDecision(
          token,
          transactionUuid,
          decision
        );
        setSuccess(true);
        return data;
      } catch (err) {
        setError(
          err.message || "An error occurred while updating the transaction."
        );
        console.error("Transaction update error:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  return { updateTransaction, isLoading, error, success };
};

const DocumentDetailsSection = ({ title, documentData }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {documentData &&
          Object.entries(documentData).map(([key, value]) => (
            <Grid item xs={6} key={key}>
              <Typography variant="body2" color="text.secondary">
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%", // Adjust width if necessary
                }}
              >
                {value || "N/A"}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);

const BiometricsSection = ({ biometrics }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Biometrics</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {biometrics &&
        biometrics.map((bio, index) => {
          const { image, ...otherFields } = bio;
          return (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              {Object.entries(otherFields).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="body2" color="text.secondary">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </Typography>
                  <Typography variant="body1">{value || "N/A"}</Typography>
                </Grid>
              ))}
              {image && (
                <Grid item xs={12}>
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt="Biometric"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "200px",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
    </AccordionDetails>
  </Accordion>
);

const BehaviourBiometricsSection = ({ behaviour }) => {
  const formatConfidenceValue = (value) => {
    const numValue = parseFloat(value);
    return (numValue * 100).toFixed(1);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Behaviour Biometrics</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Status
            </Typography>
            <Typography variant="body1">{behaviour.status || "N/A"}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              Authenticated
            </Typography>
            <Typography variant="body1">
              {behaviour.authenticated ? "Yes" : "No" || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        {behaviour.metadata?.features && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Confidence Metrics
            </Typography>
            <Grid container spacing={2}>
              {behaviour.metadata.features.map((feature) => (
                <Grid item xs={6} key={feature.name}>
                  <Box sx={{ mb: 1.5 }}>
                    <Typography variant="body2" color="text.secondary">
                      {feature.name
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                    </Typography>
                    <Typography variant="body1">
                      {formatConfidenceValue(feature.value)}%
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const ValidationStatusSection = ({ validationStatus }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Validation Status</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {validationStatus &&
          Object.entries(validationStatus).map(([key, value]) => (
            <Grid item xs={6} key={key}>
              <Typography variant="body2" color="text.secondary">
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </Typography>
              <Typography variant="body1">
                {value ? "Passed" : "Failed"}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);

const BackgroundChecksSection = ({ backgroundChecks }) => {
  if (!backgroundChecks) {
    return null;
  }

  const { person, items, events } = backgroundChecks;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Background Checks</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Person Information */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Person Details
          </Typography>
          <Grid container spacing={2}>
            {person &&
              Object.entries(person).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="body2" color="text.secondary">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </Typography>
                  <Typography variant="body1">{value || "N/A"}</Typography>
                </Grid>
              ))}
          </Grid>
        </Box>

        {/* Documents/Items Information */}
        {items && items.length > 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Items
            </Typography>
            {items.map((item, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: 1,
                    mb: index < items.length - 1 ? 2 : 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    {item.type.toUpperCase()}
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(item.description).map(([key, value]) => (
                      <Grid item xs={6} key={key}>
                        <Typography variant="body2" color="text.secondary">
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </Typography>
                        <Typography variant="body1">
                          {value || "N/A"}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {/* Alerts/Events Information */}
        {events && events.length > 0 && (
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Events
            </Typography>
            {events.map((event, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid",
                    borderColor:
                      event.type === "alert" ? "error.main" : "divider",
                    borderRadius: 1,
                    backgroundColor:
                      event.type === "alert"
                        ? "error.lighter"
                        : "background.paper",
                    mb: index < events.length - 1 ? 2 : 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    {event.type.toUpperCase()}
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(event.description).map(([key, value]) => (
                      <Grid item xs={12} key={key}>
                        <Typography variant="body2" color="text.secondary">
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color:
                              event.type === "alert"
                                ? "error.dark"
                                : "text.primary",
                          }}
                        >
                          {value || "N/A"}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const TransactionCard = ({ transaction, token, onUpdate }) => {
  const navigate = useNavigate();

  const { updateTransaction, isLoading, error, success } =
    useTransactionUpdate(token);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  if (!transaction) {
    return (
      <Typography variant="body1" color="text.secondary" align="center">
        Transaction data is unavailable.
      </Typography>
    );
  }

  const handleUpdate = async (status) => {
    const result = await updateTransaction(
      transaction.transaction.transactionUuid,
      status
    );

    if (result) {
      setOpenSnackbar(true);
      onUpdate && onUpdate(transaction.transaction.transactionUuid, status);
    }
    // Redirect if status is CLOSED
    if (status === "CLOSED") {
      if (isMobileApp()) {
        // Use custom protocol for the mobile app
        window.location.href = "odysseus-po://#";
      } else {
        // Use internal routing for desktop or browser environments
        navigate("/");
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Transaction Status:{" "}
          <strong>{transaction.transaction.transactionStatus}</strong>
        </Typography>

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Typography color="text.secondary" variant="caption">
              Traveler ID
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {transaction.transaction.travelerId}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography color="text.secondary" variant="caption">
              Travel ID
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {transaction.transaction.travelId}
            </Typography>
          </Grid>
        </Grid>

        <DocumentDetailsSection
          title="DTV-VC"
          documentData={transaction.dtoVC}
        />
        <DocumentDetailsSection
          title="DTC-PC"
          documentData={transaction.dtoPC}
        />
        <DocumentDetailsSection
          title="EMRTD"
          documentData={transaction.emrtd}
        />
        <BiometricsSection biometrics={transaction.biometrics} />
        <BehaviourBiometricsSection
          behaviour={transaction.behaviourBiometrics}
        />
        <BackgroundChecksSection
          backgroundChecks={transaction.backgroundChecks}
        />

        <ValidationStatusSection
          validationStatus={transaction.validationStatus}
        />

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            align="center"
            sx={{ mb: 2 }}
          >
            Update Transaction Status
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                status: "DTC_PC_VERIFICATION_REQUIRED",
                color: "primary",
              },
              {
                status: "EMRTD_VERIFICATION_REQUIRED",
                color: "primary",
              },
              {
                status: "DETAILED_VERIFICATION_NEEDED",
                color: "primary",
              },
              {
                status: "CLOSED",
                color: "error",
              },
            ].map(({ status, color }) => (
              <Grid item xs={12} sm={6} md={3} key={status}>
                <Button
                  variant="contained"
                  color={color}
                  onClick={() => handleUpdate(status)}
                  disabled={isLoading}
                  fullWidth
                  sx={{ height: 66 }}
                >
                  {status.replace(/_/g, " ").toUpperCase()}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isLoading ? "info" : success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isLoading
            ? "Updating transaction..."
            : success
            ? "Transaction updated successfully!"
            : error || "Failed to update transaction"}
        </Alert>
      </Snackbar>
    </Card>
  );
};

const TransactionWithDetails = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const storedUsername =
    localStorage.getItem("username") || sessionStorage.getItem("username");
  const storedPassword =
    localStorage.getItem("password") || sessionStorage.getItem("password");

  // useEffect(() => {
  //   const fetchTokenAndTransaction = async () => {
  //     setIsLoading(true);
  //     try {
  //       if (storedUsername && storedPassword) {
  //         const fetchedToken = await dispatch(
  //           fetchAccessToken({
  //             username: storedUsername,
  //             password: storedPassword,
  //           })
  //         ).unwrap();
  //         if (fetchedToken) {
  //           setToken(fetchedToken);
  //           const transaction = await getSpecificTransaction(
  //             fetchedToken,
  //             uuid,
  //             true
  //           );
  //           setTransactionData(transaction);
  //         }
  //       }
  //     } catch (err) {
  //       setError(err.message || "Failed to fetch data");
  //       console.error(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchTokenAndTransaction();
  // }, [uuid, storedUsername, storedPassword, dispatch]);

  const fetchTokenAndTransaction = useCallback(
    async (isInitialLoad = false) => {
      try {
        if (storedUsername && storedPassword) {
          if (isInitialLoad) setIsLoading(true);

          const fetchedToken = await dispatch(
            fetchAccessToken({
              username: storedUsername,
              password: storedPassword,
            })
          ).unwrap();

          if (fetchedToken) {
            setToken(fetchedToken);
            const transaction = await getSpecificTransaction(
              fetchedToken,
              uuid,
              true
            );
            setTransactionData(transaction);
          }
        }
      } catch (err) {
        setError(err.message || "Failed to fetch data");
        console.error(err);
      } finally {
        if (isInitialLoad) setIsLoading(false);
      }
    },
    [storedUsername, storedPassword, uuid, dispatch]
  );

  useEffect(() => {
    // Fetch data once on component mount with loading state
    fetchTokenAndTransaction(true);

    // Fetch data at regular intervals without loading state
    const intervalId = setInterval(() => fetchTokenAndTransaction(), 2000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [fetchTokenAndTransaction]);

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {isLoading ? (
        <Box sx={{ mt: 2, pl: 10, pr: 10 }}>
          <TableSkeleton />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      ) : (
        transactionData && (
          <TransactionCard
            transaction={transactionData}
            token={token}
            onUpdate={(uuid, newStatus) => {
              setTransactionData((prev) =>
                prev.transaction.transactionUuid === uuid
                  ? {
                      ...prev,
                      transaction: {
                        ...prev.transaction,
                        transactionStatus: newStatus,
                      },
                    }
                  : prev
              );
            }}
          />
        )
      )}
    </Container>
  );
};

export default TransactionWithDetails;
