import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Typography, Box, Snackbar, Alert } from "@mui/material";
import { fetchAccessToken } from "../../../../redux";
import {
  getCarTransaction,
  setCarTransactionDecision,
} from "../../../../services/transactions";
import TableSkeleton from "../../../../components/skeletons/TableSkeleton";
import { isMobileApp } from "../../../../utils/helpers";
import TransactionDisplay from "./TransactionDisplay";
import { useNavigate } from "react-router-dom";

const useTransactionUpdate = (token) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateTransaction = useCallback(
    async (transactionUuid, decision, actor = "user") => {
      if (!token) {
        setError("Token is missing. Please log in again.");
        return null;
      }

      setIsLoading(true);
      setError(null);
      setSuccess(false);

      try {
        const data = await setCarTransactionDecision(
          token,
          transactionUuid,
          decision
        );
        setSuccess(true);
        return data;
      } catch (err) {
        setError(
          err.message || "An error occurred while updating the transaction."
        );
        console.error("Transaction update error:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  return { updateTransaction, isLoading, error, success };
};

const CarTransactionWithDetails = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const storedUsername =
    localStorage.getItem("username") || sessionStorage.getItem("username");
  const storedPassword =
    localStorage.getItem("password") || sessionStorage.getItem("password");

  const {
    updateTransaction,
    isLoading: isUpdating,
    error: updateError,
  } = useTransactionUpdate(token);

  const handleUpdate = async (status) => {
    try {
      const result = await updateTransaction(uuid, status);

      if (result) {
        setTransactionData((prev) => ({
          ...prev,
          carTransaction: {
            ...prev.carTransaction,
            transactionStatus: status,
          },
        }));

        setSnackbarMessage("Transaction updated successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);

        if (status === "CLOSED") {
          if (isMobileApp()) {
            window.location.href = "odysseus-po://#";
          } else {
            navigate("/");
          }
        }
      }
    } catch (err) {
      setSnackbarMessage(updateError || "Failed to update transaction.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const fetchTokenAndTransaction = useCallback(
    async (isInitialLoad = false) => {
      try {
        if (storedUsername && storedPassword) {
          if (isInitialLoad) setIsLoading(true);

          const fetchedToken = await dispatch(
            fetchAccessToken({
              username: storedUsername,
              password: storedPassword,
            })
          ).unwrap();

          if (fetchedToken) {
            setToken(fetchedToken);
            const transaction = await getCarTransaction(
              fetchedToken,
              uuid,
              true
            );
            console.log(transaction);
            setTransactionData(transaction);
          }
        }
      } catch (err) {
        setError(err.message || "Failed to fetch data");
        console.error(err);
      } finally {
        if (isInitialLoad) setIsLoading(false);
      }
    },
    [storedUsername, storedPassword, uuid, dispatch]
  );

  useEffect(() => {
    // Fetch data once on component mount with loading state
    fetchTokenAndTransaction(true);

    // Fetch data at regular intervals without loading state
    const intervalId = setInterval(() => fetchTokenAndTransaction(), 2000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [fetchTokenAndTransaction]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {isLoading ? (
          <Box sx={{ mt: 2, pl: 10, pr: 10 }}>
            <TableSkeleton />
          </Box>
        ) : error ? (
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        ) : (
          <TransactionDisplay
            data={transactionData}
            handleUpdate={handleUpdate}
            isLoading={isUpdating}
          />
        )}
      </Container>

      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CarTransactionWithDetails;
