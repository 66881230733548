import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";
import { setupCrowdCountingWebSocket } from "../../utils/websocket";
import { addCrowdCountingAlerts } from "../../redux";
import { green, red } from "@mui/material/colors";

const DeviceInfoDisplay = () => {
  const dispatch = useDispatch();
  const crowdCountingState = useSelector(
    (state) => state.borderAlerts.crowd_counting
  );
  const [crowdCounting, setCrowdCounting] = useState(crowdCountingState);

  useEffect(() => {
    setCrowdCounting(crowdCountingState);
  }, [crowdCountingState]);

  useEffect(() => {
    // Set up WebSocket for alerts
    const cleanupAlertWebSocket = setupCrowdCountingWebSocket(
      (newAlertData) => {
        dispatch(addCrowdCountingAlerts(newAlertData));
      }
    );

    // Cleanup function for both WebSockets
    return () => {
      cleanupAlertWebSocket();
    };
  }, [dispatch]);

  return (
    <Box sx={{ mb: 2 }}>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: "small",
            fontFamily: "BlinkMacSystemFont",
            color: "primary.main",
          }}
        >
          Crowd Counting
        </Typography>
        {crowdCounting.length >= 1 ? (
          <Grid container spacing={2}>
            {crowdCounting.map((device, index) => (
              <Grid item xs={12} sm={6} md={4} key={device.device || index}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ color: "text.secondary", fontWeight: "bold" }}
                      gutterBottom
                    >
                      {device.device || "Unknown"}
                    </Typography>
                    <Divider />
                    {device.measures && (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            mt: 1,
                            color: "text.secondary",
                            fontWeight: "bold",
                          }}
                        >
                          People:{" "}
                          <Typography
                            variant="body1"
                            component="span"
                            sx={{
                              color:
                                device.measures.count === 0
                                  ? green[600]
                                  : red[600], // Light color for values based on condition
                              fontWeight: "bold",
                            }}
                          >
                            {device.measures.count}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              {[...Array(3)].map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Skeleton variant="text" width="50%" />
                      <Divider sx={{ my: 1 }} />
                      <Skeleton variant="text" width="30%" />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default DeviceInfoDisplay;
