import { renderActions } from "./renderUtils";

export const getColumns = (handleShowInfo) => [
  {
    field: "id",
    headerName: "ID",
    width: 60,
  },
  // {
  //   field: "transactionUuid",
  //   headerName: "UUID",
  //   width: 300,
  // },
  {
    field: "transactionStatus",
    headerName: "Status",
    width: 350,
  },
  {
    field: "travelerId",
    headerName: "Traveler ID",
    width: 130,
  },
  {
    field: "travelId",
    headerName: "Travel ID",
    width: 130,
  },
  {
    field: "personDecision",
    headerName: "Person Decision",
    width: 400,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    renderCell: (params) => renderActions(params, handleShowInfo),
    sortable: false,
    filterable: false,
  },
];

export default getColumns;
