export const calculateTravelerStats = (data) => {
  const totalTransactions = data.length;

  // Calculate status counts and percentages
  const statusCounts = data.reduce((acc, item) => {
    acc[item.transactionStatus] = (acc[item.transactionStatus] || 0) + 1;
    return acc;
  }, {});

  // Calculate traveler statistics
  const travelerCounts = {};
  data.forEach((item) => {
    travelerCounts[item.travelerId] =
      (travelerCounts[item.travelerId] || 0) + 1;
  });

  const uniqueTravelers = Object.keys(travelerCounts).length;
  const travelersWithMultipleTransactions = Object.values(
    travelerCounts
  ).filter((count) => count > 1).length;

  return {
    totalTransactions,
    statusCounts,
    uniqueTravelers,
    travelersWithMultipleTransactions,
  };
};
